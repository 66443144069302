const getters = {
  get_sede: (state) => {
    return state.sede_data;
  },

  get_sede_select: (state) => {
    return state.sede_data.map((item) => {
      return { id: item.id, nombre: item.nombre };
    });
  },
};

export default getters;
