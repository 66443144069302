const mutations = {
  SET_USER(state, data) {
    state.user_data = data;
  },

  ADD_USER(state, data) {
    state.user_data.push(data);
  },

  DELETE_USER(state, pk) {
    state.user_data = state.user_data.filter((item) => item.id != pk);
  },

  //
  SET_GROUP(state, data) {
    state.group_data = data;
  },
};
export default mutations;
