const getters = {
  get_nota: (state) => {
    return state.nota_data;
  },

  get_nota_by_dni: (state) => (dni) => {
    return state.nota_data.find((item) => item.dni == dni);
  },
};
export default getters;
