const mutations = {
  SET_POSTULANTE(state, data) {
    state.postulante_data = data;
  },

  VALIDAR_LOCALMENTE(state, value) {
    const { id, is_valid } = value;
    const index = state.postulante_data.findIndex((item) => item.id == id);
    if (index > -1) {
      state.postulante_data[index].validado = is_valid;
    }
  },
};
export default mutations;
