/* eslint-disable no-unused-vars */
import axios from "@/plugins/axios";

const actions = {
  async set_nota({ commit }, params = { dni: "", limit: "true" }) {
    try {
      let { dni, limit } = params;
      dni = dni || "";
      limit = limit || "true";
      const { data } = await axios.get("get_nota", {
        params: {
          dni,
          limit,
        },
      });
      commit("SET_NOTA", data);
      return data;
    } catch (error) {
      return null;
    }
  },

  async add_nota({ commit }, payload) {
    try {
      const { data } = await axios.post("nota/", payload);
      commit("ADD_NOTA", data);
      return data;
    } catch (error) {
      //
      return null;
    }
  },

  async update_nota({ commit }, payload) {
    try {
      const { data } = await axios.put("nota/", payload);
      commit("UPDATE_NOTA", data);
      return data;
    } catch (error) {
      //
      return null;
    }
  },

  async delete_nota({ commit }, pk) {
    try {
      const { data } = await axios.delete(`nota/${pk}/`);
      commit("DELETE_NOTA", pk);
      return data;
    } catch (error) {
      //
    }
  },
};
export default actions;
