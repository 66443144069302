import axios from "axios";

const is_dev = process.env.NODE_ENV == "development"

let url =
    is_dev
        ? process.env.VUE_APP_BASE_URL
        : process.env.VUE_APP_URL;

let service = axios.create({
    baseURL: url,
});

service.interceptors.request.use(
    function (config) {
        let token = JSON.parse(localStorage.getItem("token-oca-unap"));
        //
        let other_domain = JSON.parse(localStorage.getItem("other-domain"))
        if (!other_domain) {
            if (is_dev) other_domain = process.env.VUE_APP_DEFAULT_DOMAIN
            else other_domain = process.env.VUE_APP_DEFAULT_DOMAIN_BUILD
        } else other_domain = other_domain.domain_url
        //

        const port = process.env.VUE_APP_DEFAULT_PORT

        if (url !== other_domain) {
            if (is_dev)
                config.baseURL = `https://${other_domain}:${port}/api/v1/`
            else
                config.baseURL = `https://${other_domain}/api/v1/`
        }

        if (token && config.url !== "/login") {
            config.headers.Authorization = `token ${token}`;
        }

        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);
export default service;
