import axios from "@//plugins//axios.js";

const actions = {
  async set_sede({ commit }) {
    try {
      const { data } = await axios.get("cede");
      commit("SET_SEDE", data);
      return data;
    } catch (error) {
      return null;
    }
  },
};

export default actions;
