import axios from "@//plugins//axios.js";

const actions = {
  async set_modalidad({ commit }) {
    try {
      const { data } = await axios.get("modalidad");
      commit("SET_MODALIDAD", data);
      return data;
    } catch (error) {
      return null;
    }
  },
};

export default actions;
