const mutations = {
    SET_SCREEN(state, data) {
        state.screen = data;
    },

    SET_SCREEN_TEXT(state, data) {
        state.screen_text = data;
    },

    SET_DOMAIN(state, data) {
        state.domain = data;
    },
};
export default mutations;
