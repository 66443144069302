import axios_public from "@/plugins/axios_db_public";

const actions = {
    async uploadSchools({ commit }, payload) {
        try {

            const response  = await axios_public.post("schools/upload-school/", payload);
            console.log('response', response)
            commit("", []);
            return response.data ;
        } catch (error) {
            commit("", []);
            console.log('error_:', error.response.data)
            return error.response.data;
        }
    },

    async getListSchools({ commit }) {
        try {

            const { data }= await axios_public.get("schools/list-schools/");
            commit("set_schools", data);
        } catch (error) {
            commit("setSchools", []);
        }
    },



}
export default actions;