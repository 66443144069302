const mutations = {
  SET_PAGO(state, data) {
    state.pago_data = data;
  },

  ADD_PAGO(state, data) {
    data.forEach((element) => {
      let obj = {
        id: element.id,
        nombre: element.nombre,
        num_doc: element.dni,
        fecha: element.fecha,
        monto: element.monto,
        num_operacion: element.num_ticket,
        fecha_inscripcion: "",
        is_exonerado: element.is_exonerado,
        documento_referencial: "",
        modalidad: element.modalidad,
      };

      state.pago_data.unshift(obj);
    });
  },

  UPDATE_PAGO(state, data) {
    data.forEach((element) => {
      const index = state.pago_data.findIndex((item) => item.id == element.id);

      if (index > -1) {
        state.pago_data[index] = {
          id: element.id,
          nombre: element.nombre,
          num_doc: element.dni,
          fecha: element.fecha,
          monto: element.monto,
          num_operacion: element.num_ticket,
          fecha_inscripcion: state.pago_data[index].fecha_inscripcion,
          is_exonerado: element.is_exonerado,
          documento_referencial: element.documento_referencial,
          modalidad: element.modalidad,
        };
      }
    });
  },

  //
};
export default mutations;
