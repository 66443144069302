import axios from "@/plugins/axios";

const actions = {
  async set_pago({ commit }, params = { dni: "" }) {
    try {
      let { dni, limit, sede } = params;
      dni = dni || "";
      limit = limit || "true";
      sede = sede || "";

      const { data } = await axios.get("get_pagos_banko/", {
        params: {
          dni,
          limit,
          sede,
        },
      });
      commit("SET_PAGO", data);
      return data;
    } catch (error) {
      return null;
    }
  },

  async add_pago({ commit }, payload) {
    try {
      const { data } = await axios.post("pagos_banko/", payload);
      let ok = data[0].id;
      if (!ok) return "exonerado-repetido";
      commit("ADD_PAGO", data);
      return data;
    } catch (error) {
      //
      return null;
    }
  },

  async update_pago({ commit }, payload) {
    try {
      const { data } = await axios.put("pagos_banko/", payload);
      commit("UPDATE_PAGO", data);
      return data;
    } catch (error) {
      //
      return null;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async set_pago_banco_by_id({ commit }, id) {
    try {
      const { data } = await axios.get("get_pagos_banko_by_id/", {
        params: { id },
      });

      return data[0];
    } catch (error) {
      return null;
    }
  },
};
export default actions;
