import Vue from "vue";

function Parsear(val) {
  const formatterPeso = new Intl.NumberFormat("es-PE", {
    currency: "PEN",
    minimumFractionDigits: 2,
  });
  val = Math.round(val * 100) / 100;
  return formatterPeso.format(val);
}

Vue.filter("parseMoney", function(value) {
  return Parsear(value);
});
