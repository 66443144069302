const getters = {
  get_menu_by_user_type: (state) => (type) => {
    let data = state.menu_data.filter((row) => {
      let filter = row.user_permission.filter((item) => item == type).length;
      if (filter && row.state) return row;
    });

    return data;
  },
};
export default getters;
