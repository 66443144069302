const state = {
  menu_data: [
    {
      name: "/home",
      text: "Inicio",
      icon: "fas fa-home",
      link: "/home",
      user_permission: [
        "administrador",
        "usuario",
        "externo",
        "digitador",
        "sede",
        "consultor",
      ],
      state: false,
    },

    {
      name: "/user",
      text: "Usuarios",
      icon: "fas fa-user",
      link: "/user",
      user_permission: ["administrador", "usuario"],
      state: true,
    },

    {
      name: "asignacion_sillas",
      text: "Asignacion Sillas",
      icon: "fas fa-chair",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "vacantes",
      text: "Vacantes",
      icon: "fas fa-stream",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "periodos",
      text: "Periodos",
      icon: "fas fa-calendar-alt",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "modalidades",
      text: "Modalidades",
      icon: "fas fa-id-card",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "requisitos",
      text: "Requisitos",
      icon: "fas fa-clipboard-list",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "notas_postulante",
      text: "Notas Postulante",
      icon: "fas fa-list-alt",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "postulante",
      text: "Postulantes",
      icon: "fas fa-users",
      user_permission: [
        "administrador",
        "usuario",
        "digitador",
        "sede",
        "consultor",
      ],
      state: true,
    },

    {
      name: "registro_examen",
      text: "Registro Examen",
      icon: "fas fa-pen",
      user_permission: ["administrador"],
      state: true,
    },

    {
      name: "nuevo_postulante",
      text: "Nuevo Postulante",
      icon: "fas fa-user-plus",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "gratuidad",
      text: "Gratuidad",
      icon: "fas fa-star",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "reinscripcion",
      text: "Reinscripcion",
      icon: "fas fa-exchange-alt",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "entrega_prospecto",
      text: "Entrega Prospecto",
      icon: "fas fa-file-alt",
      user_permission: ["administrador", "usuario"],
      state: false,
    },

    {
      name: "registro_pago",
      text: "Registro Pagos",
      icon: "fas fa-file-alt",
      user_permission: ["externo", "administrador", "sede"],
      state: true,
    },

    {
      name: "registro_nota",
      text: "Registro Nota",
      icon: "fas fa-file-alt",
      user_permission: ["administrador", "digitador", "sede", "evaluadores"],
      state: true,
    },

    {
      name: "recepcion_documento",
      text: "Recepcion Documentos",
      icon: "fas fa-file-alt",
      user_permission: ["administrador", "digitador"],
      state: true,
    },

    {
      name: "vacante",
      text: "Vacante",
      icon: "fas fa-file-alt",
      user_permission: ["administrador"],
      state: true,
    },

    {
      name: "importar_asiento",
      text: "Importar Asiento",
      icon: "fas fa-chair",
      user_permission: ["administrador"],
      state: true,
    },

    {
      name: "importar_puntaje",
      text: "Importar Puntaje",
      icon: "fas fa-sort-numeric-up",
      user_permission: ["administrador"],
      state: true,
    },
    {
      name: "upload_school",
      text: "Importar Colegios",
      icon: "fas fa-sort-numeric-up",
      user_permission: ["administrador"],
      state: true,
    }
  ],
};
export default state;
