const mutations = {
  SET_AUTH(state, data) {
    state.auth_data = data;
  },

  CLEAR_AUTH(state) {
    state.auth_data = {};
    localStorage.setItem("token-oca-unap", null);
  },
};
export default mutations;
