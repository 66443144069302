import axios from "axios";

const url = process.env.VUE_APP_BASE_URL_PUBLIC_BACKEND

let service_public = axios.create({
    baseURL: url,
});

// service_public.interceptors.request.use(
//     function (config) {
//         let token = JSON.parse(localStorage.getItem("token-oca-unap"));
//
//         if (token){
//             config.headers.Authorization = `token ${token}`;
//         }
//     },
//     function (err) {
//         return Promise.reject(err);
//     }
// );


export default service_public;